import pako from 'pako';
import { encode } from '@urlpack/base62';

function compress(text) {
    const container = { v: 2, t: text };
    const compressed = pako.deflate(JSON.stringify(container));
    const compressedBase62 = encode(compressed);
    return compressedBase62;
}

export default compress;