import React, { Component } from "react"
import logo from "./oreno.svg"
import "./App.css"
import compress from "./compress"

class EditArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      msg: null,
      value: 'Hello world.',
      generated_url: window.location.origin + "/c?p=" + compress("Hello world."),
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleClickCopy = this.handleClickCopy.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }

  handleChange(event) {
    this.setState({
      value: event.target.value,
      generated_url: window.location.origin + "/c?p=" + compress(event.target.value)
    });
  }

  handleClickCopy(event) {
    let { generated_url } = this.state
    console.log("copy", generated_url)
    navigator.clipboard.writeText(generated_url)
  }

  handleClickOpen(event) {
    let { generated_url } = this.state
    console.log("open", generated_url)
    const newWindow = window.open(generated_url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  render() {
    let { value } = this.state

    return (
      <>
        <div className="App-edit-area">
          <label className="App-editor-label" htmlFor="input-text">
            Input HTML here.
            <div>
              <textarea className="App-editor" id="input-text" alt="Input text here." rows={Math.round(this.state.storyHeight * 10).toString()} onChange={this.handleChange} value={value} />
            </div>
          </label>
          {/* https://heroicons.com/ */}
          <button className="App-copy-button" alt="COPY URL" onClick={this.handleClickCopy}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
            </svg>COPY
          </button>
          <button className="App-copy-button" alt="OPEN NEW WINDOW" onClick={this.handleClickOpen}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>OPEN
          </button>
        </div>
      </>
    )
  }
}

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Share your HTML with others.
          </p>
          <EditArea />
        </header>
      </div>
    )
  }
}

export default App
